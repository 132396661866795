@import "../partials/mixins";

#main{
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc( 100% - 45px );
  border: 1px solid;
  border-top: 0px;
  border-bottom: 0px;
  padding: 80px 40px 40px 40px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  & > div{
    opacity: 0;
    @include animation(fadeIn .4s .2s forwards);
  }

  p {
    font-weight: 400;
    font-size: 1.1em;
  }

  .content {
    max-width: 840px;
    margin: auto;

    .header{
      text-align: center;
      i{
        font-size: 8em;
        margin-bottom: 10px;
      }
    }
    @include media-query(phone){
      font-size: .9em;

    }
  }

  @include media-query(phone-tablet) {
    max-height: calc( 100% - 40px );
  }

  @include media-query(phone){
    padding: 50px 20px 20px 20px;
  }

}
