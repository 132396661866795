@import "../partials/mixins";

#topbar{
  height: 8vh;
  max-height: 45px;
  #top-nav {
    .nav-links {
      .tabs {
        width: 100%;
        display: flex;
        font-size: 1.2em;
        a{
          display: inline-block;
          width:  calc(25%);
          &.active,
          &:hover{
            .tab{
              @include border-radius(4px 4px 0px 1px);
              border: 1px solid;
              border-bottom-width: 0;
              border-left-width: 3px;
              @include transition();
            }
          }
          .tab{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 6vh;
            max-height: 35px;
            margin-top: 10px;
            text-align: center;
            text-decoration: none;
            border-style: solid;
            border-right-width: 1px;
            border-left-width: 0px;
            border-bottom-width: 1px;
            border-top-width: 0px;
            @include transition();

          }
        }
      }
    }
  }

  @include media-query(phone-tablet) {
    max-height: 40px;
    #top-nav {
      .nav-links {
        .tabs {
          a {
            .tab {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
