.contact-container{
  font-family: 'Inconsolata', monospace;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .content{
    max-width: 940px;
    margin: auto;
    .form-container{
      width: 100%;
      form{
        display: block;
        width: 100%;
        font-size: 1.2em;
        margin-top: 20px;
        .first-level{
          padding-left: 5px;
          margin-bottom: 10px;
        }
        .second-level{
          padding-left: 40px;
          margin-bottom: 20px;
        }
        .third-level{
          padding-left: 25px;
          width: 300px;
        }
        input,
        textarea{
          border: none;
          border-bottom: 2px #94c873 solid;
          width: 100%;
          line-height: 25px;
          border-radius: 2px;
        }

        input[type="submit"]{
          width: 150px;
          float: right;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          &:focus{
            outline: none;
          }

        }
      }

    }
  }
}
