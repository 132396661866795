@import "../partials/mixins";

.home-container{
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .content{
    max-width: 940px;
    margin: auto;
    text-align: center;
    .avatar{
      display: block;
      margin: auto;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    div{
      .greeting{
        font-weight: 700;
        font-size: 4em;
        margin: 25px 0 5px;
      }
      .tag-line{
        font-weight: 600;
        font-size: 3em;
        margin-bottom: 20px;
        // text-shadow: .5px .5px lighten($dark-main-color-1-light, 10%);
      }
      @include media-query(phone){
        font-size: .8em;
      }
    }
    //social icons
    #social-icons{
      a{
        i{
          font-size: 3em;
          margin-right: 10px;
          margin-left: 10px;
        }
        @include media-query(phone-tablet){
          i{
            font-size: 2.5em;

          }

        }
      }
    }
  }
}
