@import "../partials/animations";

#sidebar{
  position: relative;
  display: block;
  height: 100%;
  #side-nav{
    a{
      text-decoration: none;
    }
    padding-top: 20px;
    .nav-links{
      .tree-level{
        position: relative;
        left: -400px;
        display: block;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        span{
          display: inline-block;
          font-size: 1.2em;
          line-height: 1em;
          font-weight: bold;
          font-family: 'Signika Negative', sans-serif;
          margin: 0px;
        }
        &.first{
          @include animation(slide-in-left .2s .2s forwards);
          padding-left: 5px;
        }
        &.second{
          @include animation(slide-in-left .2s .4s forwards);
          padding-left: 25px;
        }
        &.third{
          @include animation(slide-in-left .2s .6s forwards);
          padding-left: 50px;
        }
        &::before{
          margin-right: 5px;
          font-size: 1.2em;
          line-height: 1em;
          vertical-align: top;
        }
        .folder{
          &::before{
            margin-right: 5px;
            font-size: 1.5em;
            line-height: 1em;
          }
        }

      }
      .files{
        position: relative;
        overflow: hidden;

        div{
          cursor: pointer;
        }
      }
    }
  }
  // sidebar social icons
  .email-container{
    position: absolute;
    bottom: -500px;
    width: 100%;
    margin-bottom: 50px;
    @include animation(slide-up .3s 30s forwards);
    .email-widget{
      display: block;
      width: 150px;
      height: 60px;
      margin: auto;
      @include animation(fliker 2s 29s 10);
      i{
        width: inherit;
        height: inherit;
        font-size: 60px;
        line-height: 65px;
        text-align: center;
      }
      p{
        font-size: 1.3em;
        margin-top: 5px;
        text-align: center;
        text-decoration: none;
        font-weight: bolder;
      }
      i,p{
        // @include opacity(.2);
        @include transition();
      }
      &:hover{
        i,p{
          @include opacity(1);
        }
      }
    }
  }



}
