@import "../partials/mixins";

*{
  box-sizing: border-box;
}
html,body{
  margin: 0;
  padding: 0;
  font-family: 'Signika Negative', sans-serif;
  font-size: 14px;
  a{
    text-decoration: none;
  }
  #app{
    overflow: hidden;
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    .left-container,
    .right-container{
      display: inline-block;
      position: relative;
      height: 93vh;
      @include transition(all 0.3s ease-out 0s);
    }
    .left-container{
      // initial width, which will change with animation
      width: 0%;
      float: left;
      @include animation(open-panel-left .3s .2s forwards);

    }
    .right-container{
      width: 100%;
      float: right;
      @include animation(open-panel-right .3s .2s forwards);
    }

    // social icons
    #social-icons{
      a{
        display: inline-block;
        i{
          position: relative;
          text-align: center;
          line-height: inherit;
          @include opacity(.5);
          &:hover{
            @include transition();
            @include opacity(1);
            &.twitter{
              color: #55acee;
            }
            &.linked-in{
              color: #0077b5;
            }
            &.github{
              color: #4183c4;
            }
            &.website{
              color: #94c873;
            }
          }
        }
      }
    }

    // media queries
    @include media-query(phone-tablet){
      .left-container{
        width: 0%;
        @include animation(none);
        .nav-links,
        .email-container{
          // @include transition(all .1s ease-out 0s);
          @include transform(translateX(-300px));
        }
      }
      .right-container{
        width: 100%;
        @include animation(none);
      }
    }

    @include media-query(medium-screen){
      .left-container{
        width: 30%!important;
        @include animation(none);
      }
      .right-container{
        width: 70%!important;
        @include animation(none);
      }
    }
    // switch button to chnage between themes
    #switch{
      position: fixed;
      right: .5em;
      bottom: 8vh;

      button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        &:active,
        &:focus {
          outline: none;
        }
        &:hover{
          .tool-tip{
            visibility: visible;
          }
        }
        .tool-tip{
          visibility: hidden;
          position: absolute;
          z-index: 100;
          width: 200px;
          left: -210px;
          bottom: 0px;
          border-radius: 5px;
          &:before{
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid;
            right: -16px;
            top: 40%;
          }
          span{
            font-size: 1.2em;
            font-weight: bold;
            text-transform: uppercase;
            &.dark{

            }
          }
        }
        i{
          @include prefix(opacity, .5);
          font-size: 2em;
          transition: opacity .2s ease-out;
          &:hover{
            @include prefix(opacity, 1);
          }
        }
      }
    }

  }
}
