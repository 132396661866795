@import "../partials/mixins";

.about-container{
  max-width: 840px;
  margin: auto;
  .content{
    .header{

      .fun-facts{
        line-height: 20px;
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        @include media-query(phone-tablet){
          font-size: 1em;
        }
        .title{

        }
        .dynamic-content{

        }
      }

    }
    .body{
      .who{
        margin-bottom: 40px;
        p{
          margin-left: 20px;
        }
      }
      .skills{
        .tech-stack{
          margin-left: 20px;
          h3{
            margin-bottom: 5px;
          }
          div{
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
