@import "../partials/mixins";

#footer{
  display: flex;
  flex-direction: row;
  position: relative;
  height: 7vh;
  clear: both;
  border-top: 1px solid;
  .left-side,
  .right-side{
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(50% - 2px);
  }
  .left-side{
    padding-left: 15px;
    height: inherit;
    #social-icons{
      a{
        i{
          font-size: 2em;
          margin-right: 10px;
        }
        @include media-query(phone-tablet){
          i{
            font-size: 1.5em;
          }

        }
      }
    }

  }
  .right-side{
    padding-right: 15px;
    div{
      margin-left: auto;
    }
    .copy-right{
      // font-size: 1em;
      font-weight: lighter;
      @include opacity(.8);
      i{
        font-size: 1.5em;
        margin:5px;
      }
      p{
        font-size: 1.2em;
        display: inline-block;
        margin: 0px;
      }
      @include media-query(phone){
        font-size: .7em;
      }
    }
  }

}
